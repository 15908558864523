import BaseService from './baseService'
import api from '../utils/api'

class OfficialService extends BaseService {
  get url () {
    return 'official-info'
  }

  get () {
    return api.get(this.url)
  }

  update (item) {
    return api.patch(this.url, item)
  }

}

export default new OfficialService()

