<template>
  <div>
    <official-persons-list
      :items="value"
      v-on="{
        [EVT_REORDERING]: ($event) => reordering($event),
        remove,
        edit,
        create,
      }"
      v-if="value.length"
      class="mb-4"
    />

    <v-btn
      :disabled="state.mode !== MODE_LIST"
      @click="create" color="primary"
    >
      Добавить
    </v-btn>

    <v-dialog
      max-width="550px"
      v-if="isDialogVisible"
      :value="true"
      @click:outside="cancel"
    >
      <div class="text-h6 mb-4 d-flex align-center justify-space-between">
        <div>
          {{ state.mode === MODE_EDIT ? 'Редактирование' : 'Создание' }}
        </div>
        <v-btn @click="cancel" fab depressed icon small><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <validation-observer slim v-slot="{ handleSubmit }">
        <official-person v-model="state.person" />
        <div class="mt-8" style="display: flex; justify-content: space-between">
          <v-btn color="success" @click="handleSubmit(submit)">Сохранить</v-btn>
          <v-btn @click="cancel">Отмена</v-btn>
        </div>
      </validation-observer>
    </v-dialog>
  </div>
</template>

<script>
import OfficialPersonModel from '@/components/Official/OfficialPerson'
import OfficialPerson from '@/components/Official/OfficialPerson.vue'
import OfficialPersonsList, { EVT_REORDERING } from '@/components/Official/OfficialPersonsList.vue'

const MODE_EDIT = 'edit'
const MODE_CREATE = 'add'
const MODE_LIST = 'list'

const stateEdit = (person, idx) => ({
  mode: MODE_EDIT,
  person: new OfficialPersonModel(person),
  editing: idx
})

const stateCreate = () => ({
  mode: MODE_CREATE,
  person: new OfficialPersonModel(),
  editing: null,
})

const stateList = () => ({
  mode: MODE_LIST,
  person: null,
  editing: null,
})

export default {
  components: {
    OfficialPerson,
    OfficialPersonsList
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      EVT_REORDERING,
      MODE_LIST,
      MODE_CREATE,
      MODE_EDIT,
      state: stateList(),
    }
  },
  computed: {
    isDialogVisible () {
      return [MODE_CREATE, MODE_EDIT].includes(this.state.mode)
    }
  },
  methods: {
    create () {
      this.state = stateCreate()
    },
    edit (idx) {
      const person = this.value[idx]
      this.state = stateEdit(person, idx)
    },
    cancel () {
      this.state = stateList()
    },
    remove (idx) {
      const value = this.value.slice()
      this.$delete(value, idx)
      this.$emit('input', value)
    },
    add () {
      this.$emit('input', [ ...this.value, this.state.person ])
      this.state = stateList()
    },
    update () {
      const persons = [ ...this.value ]
      const idx = this.state.editing
      const person = this.state.person
      persons[idx] = person
      this.$emit('input', persons)
      this.state = stateList()
    },
    submit () {
      if (this.state.mode === MODE_CREATE) {
        return this.add()
      } else if (this.state.mode === MODE_EDIT) {
        return this.update()
      }
    },
    reordering (items) {
      this.$emit('input', items)
    }
  }
}
</script>
