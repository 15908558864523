<template>
  <validation-observer
    tag="div"
    v-slot="{ validate, errors }"
  >
    <page-header
      class="mb-2"
      sm12
      title="Публичная информация"
    >
      <template v-slot:right>
        <v-btn
          right
          color="success"
          :loading="pending"
          @click.prevent="save"
        >
          <v-icon small>mdi-content-save</v-icon>
          {{ pending ? 'Сохранение...' : 'Сохранить' }}
        </v-btn>
      </template>
    </page-header>

    <div v-if="!item && pending">Loading...</div>

    <v-card v-else-if="item">
      <v-card-title>
        Содержимое
      </v-card-title>
      <v-card-text>
        <locale-field
          component="v-text-field"
          outlined
          dense
          label="Название организации"
          hide-details
          name="title"
          v-model="item.title"
        />

        <locale-field
          class="mt-4"
          component="v-textarea"
          :rows="2"
          outlined
          auto-grow
          dense
          label="Описание организации"
          hide-details
          name="description"
          v-model="item.description"
        />
      </v-card-text>

      <v-card-title>
        Адрес
      </v-card-title>
      <v-card-text>
        <locale-field
          component="v-text-field"
          dense
          outlined
          hide-details
          name="addressType"
          v-model="item.address.type"
          label="Тип адреса"
          rules="required"
        />
        <locale-field
          class="mt-4"
          component="v-textarea"
          dense
          outlined
          hide-details
          name="addressText"
          v-model="item.address.address"
          label="Расположение"
          rules="required"
          auto-grow
          :rows="2"
        />
      </v-card-text>

      <v-card-title>
        Контактное лицо
      </v-card-title>
      <v-card-text>
        <official-persons
          name="contacts"
          v-model="item.contacts"
        />
      </v-card-text>

    </v-card>
  </validation-observer>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import LocaleField from '@/components/Locale/LocaleField.vue'
import officialService from '@/services/official'
import OfficialPersons from '@/components/Official/OfficialPersons.vue'

export default {
  components: {
    PageHeader,
    LocaleField,
    OfficialPersons
  },
  data () {
    return {
      pending: false,
      error: null,
      item: null,
    }
  },
  created () {
    return this.fetch()
  },
  methods: {
    async fetch () {
      let error
      this.pending = true
      try {
        this.item = await officialService.get()
      } catch (e) {
        error = error
      }
      this.pending = false
      this.error = error
    },
    async save () {
      let error
      this.pending = true
      try {
        await officialService.update(this.item)
      } catch (e) {
        error = e
      }
      this.pending = false
      this.error = error
    }
  }
}
</script>
