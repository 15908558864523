<template>
  <items-order-provider
    :items="items"
    @input="reordering"
    v-slot="{ next, prev }"
  >
    <ul class="opl">
      <li
        v-for="(item, idx) in persons"
        :key="item.key"
      >
        <div class="d-flex align-center" style="grid-gap: 20px">
          <div>
            <order-arrows
              :last="idx === persons.length - 1"
              :first="idx === 0"
              @prev="prev(idx)"
              @next="next(idx)"
            />
          </div>
          <div>
            {{ item.position }}, {{ item.name }}
          </div>
          <div class="d-flex" style="grid-gap: 20px">
            <v-btn
              v-for="action in item.actions"
              :key="action.key"
              @click="action.handler"
              icon
              small
              :title="action.title"
            >
              <v-icon small v-if="action.icon">{{  action.icon }}</v-icon>
            </v-btn>
          </div>
        </div>
        <ul class="opl__contacts" v-if="item.contacts && item.contacts.length">
          <li v-for="contact in item.contacts" :key="contact.$key">
            {{ contact.name }}:
            {{  contact.value }}
          </li>
        </ul>
      </li>
    </ul>
  </items-order-provider>
</template>

<script>
import ItemsOrderProvider from '@/components/ItemsOrderProvider.vue'
import OrderArrows from '@/components/OrderArrows.vue'

export const EVT_REMOVE = 'remove'
export const EVT_EDIT = 'edit'
export const EVT_REORDERING = 'reordering'

export default {
  components: {
    ItemsOrderProvider,
    OrderArrows
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  computed: {
    persons () {
      return this.items.map(({ fullName, position, ...contacts }, idx) => ({
        name: [fullName.ru, `(${fullName.en})`].join(' '),
        position: [position.ru, `(${position.en})`].join(' '),
        contacts: Object
          .entries(contacts)
          .filter(([key, value]) => value)
          .map(([key, value]) => ({ value, name: key, $key: key })),
        actions: [
          {
            key: 'edit',
            icon: 'mdi-pencil',
            title: 'Изменить',
            handler: () => this.$emit(EVT_EDIT, idx)
          },
          {
            key: 'remove',
            icon: 'mdi-close',
            title: 'Удалить',
            handler: () => this.cancel(idx)
          },
        ]
      }))
    }
  },
  methods: {
    cancel (idx) {
      if (!confirm('Удалить контакт?')) return
      this.$emit(EVT_REMOVE, idx)
    },
    reordering (items) {
      this.$emit(EVT_REORDERING, items)
    }
  }
}
</script>

<style scoped lang="scss">
.opl {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.opl > * {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.opl > *:last-child {
  margin-bottom: 0;
}

.opl__contacts {
  margin: 8px 0 0;
  padding: 0;
  & > * {
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
