export default class OfficialPerson {
  constructor (data) {
    const {
      position = {},
      fullName = {},
      telegram = null,
      phone = null,
      email = null,
      whatsapp = null
    } = data || {}

    Object.assign(this, {
      position,
      fullName,
      telegram,
      phone,
      email,
      whatsapp
    })
  }
}
