<template>
  <div>
    <locale-field
      component="v-text-field"
      v-bind="attrs"
      name="position"
      rules="required"
      label="Должность"
      v-model="form.position"
    />

    <locale-field
      class="mt-4"
      component="v-text-field"
      v-bind="attrs"
      name="fullName"
      rules="required"
      label="Полное имя"
      v-model="form.fullName"
    />

    <v-text-field
      class="mt-8"
      v-bind="attrs"
      v-model="form.telegram"
      name="telegram"
      label="Telegram"
    />

    <v-text-field
      class="mt-4"
      v-bind="attrs"
      v-model="form.phone"
      name="phone"
      label="Телефон"
    />

    <v-text-field
      class="mt-4"
      v-bind="attrs"
      v-model="form.email"
      name="email"
      label="E-mail"
    />

    <v-text-field
      class="mt-4"
      v-bind="attrs"
      v-model="form.whatsapp"
      name="whatsapp"
      label="Whatsapp"
    />
  </div>
</template>

<script>
import LocaleField from '@/components/Locale/LocaleField.vue'
import isEqual from 'lodash/isEqual'
import Officialperson from './OfficialPerson'

export default {
  components: {
    LocaleField
  },
  model: {
    prop: 'person',
    event: 'input',
  },
  props: {
    person: {
      type: Object,
    }
  },
  data () {
    return {
      attrs: {
        outlined: true,
        dense: true,
        hideDetails: true
      },
      form: null,
    }
  },
  watch: {
    person: {
      deep: true,
      immediate: true,
      handler (person) {
        if (isEqual(this.form, person)) return
        this.form = new Officialperson(person || {})
      }
    },
    form: {
      deep: true,
      handler (form) {
        this.$emit('input', form)
      }
    }
  }
}
</script>
